import React, { FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';

import {
    AlertContent,
    AlertDialogInnerWrapper,
    AlertDialogWrapper,
    DialogBodyText,
    DialogButton,
    DialogButtonContainer,
    DialogTitle,
    ErrorMessage,
    HintText,
    SubtitleText,
} from './AlertDialog.css';
import { withNoScroll } from '../../hooks/withNoScroll/withNoScroll';

export type AlertPropTypes = {
    title: string;
    bodyText?: string;
    hintText?: string;
    subTitle: string;
    buttons?: Array<{
        text: string;
        onClick: () => void;
    }>;
    errorMessage?: number;
};

const Dialog: FC<AlertPropTypes> = ({ title, bodyText, hintText, buttons, subTitle, errorMessage }) => {
    if (!title && !bodyText && !buttons.length) {
        return null;
    }

    const dialogButtons: ReactNode[] = buttons
        ? buttons.map((button, index) => (
              <DialogButton
                  data-test-id={'guest-message-continue-as-a-guest'}
                  key={`dialog-button-${index}`}
                  onClick={button.onClick}
                  isPrimary={index === buttons.length - 1}
                  label={button.text}
                  link={null}
                  data-event-name={'click'}
                  data-track-id={'button_dialog'}
                  data-button-title={button.text}
              />
          ))
        : null;

    const modalRoot = document.getElementById('modal-root');

    // check if modalRoot exists in the DOM before rendering the modal which is causing the "Target container is not a DOM element." error in some cases
    if (modalRoot) {
        return createPortal(
            <AlertDialogWrapper>
                <AlertDialogInnerWrapper>
                    <AlertContent>
                        <DialogTitle data-test-id={'guest-error-message'}>{title}</DialogTitle>
                        {subTitle && <SubtitleText>{subTitle}</SubtitleText>}
                        {bodyText && <DialogBodyText>{bodyText}</DialogBodyText>}
                        {hintText && <HintText>{hintText}</HintText>}
                        {dialogButtons?.length > 0 && <DialogButtonContainer>{dialogButtons}</DialogButtonContainer>}
                    </AlertContent>
                    {hintText && <HintText>{hintText}</HintText>}
                    {dialogButtons?.length > 0 && <DialogButtonContainer>{dialogButtons}</DialogButtonContainer>}
                    <ErrorMessage>{errorMessage}</ErrorMessage>
                </AlertDialogInnerWrapper>
            </AlertDialogWrapper>,
            modalRoot
        );
    }
};

export const AlertDialog = withNoScroll(Dialog);
